import { StyleSheet } from "react-native";

const colors = {
    slate: '#363636',
    gold: '#ebce9e',
    action: 'rgba(255,255,255,0.5)',
    actionCurrent: 'rgba(255,255,255,1)',
    green: '#5daa44',
    red: '#d62920',
}

export default function globalStyles( window ) {

    const vh = window.height * 0.01;
    const vw = window.width * 0.01;
    const isLandscape = ( vh < vw );
    // Viewport Unit
    const vu = ( vh < vw ? vh : vw );
    let em = 2*vu;
    if ( em > 16 ) em = 16;

    return StyleSheet.create({

        page: {
            flex: 1,
            height: 100*vh,
            width: 100*vw,
            top: 0,
            left: 0,
            position: 'absolute'
        },
        pageScrollView: {
            flex: 1,
        },
        pageContent: {
            marginTop: 10*vh,
            marginBottom: 10*vh
        },
        hrFaded: {
            height: 0.25*vu,
            width: 80*vu,
            alignSelf: 'center'
        },
        paragraph: {
            marginBottom: ( isLandscape ? 2*vu : 6*vu ),
            textAlign: 'left'
        },
        bulletRow: {
            flexDirection: 'row'
        },
        indent: {
            width: 1.5*em
        },
        subnote: {
            fontSize: ( isLandscape ? 1*em : 2*em ),
            marginTop: 0,
            marginBottom: ( isLandscape ? 4*vu : 12*vu ),
        },

        headerIconsWrapper: {
            flexDirection: 'row'
        },
        headerIconButton: {
            marginRight: 4*vu,
            padding: 2*vu,
            alignItems: 'flex-start'
        },
        headerIconRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        headerIconText: {
            marginLeft: 1*vu,
            color: colors.action,
            width: ( isLandscape ? 'auto' : 0 ),
            height: ( isLandscape ? 'auto' : 0 ),
            opacity: ( isLandscape ? 1 : 0 )
        },
        headerIconTextCurrent: {
            fontFamily: 'Montserrat_700Bold',
            color: colors.actionCurrent,
        },
        headerIconArrow: {
            alignItems: 'center',
        },
        menuTitleText: {
            marginLeft: 3*vu,
            color: colors.action
        },

        menuIconWrapper: {
            position: 'absolute',
            top: ( isLandscape ? 0 : 2*vu ),
            left: 0,
            flexDirection: 'row'
        },
        menuIcon: {
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 2*vu,
            padding: 2*vu,
            shadowColor: "#444444",
            shadowOffset: {
                width: 0,
                height: 0
            },
            shadowOpacity: 1,
            shadowRadius: 3*vu,
            elevation: 2,
        },

        navDrawer: {
            backgroundColor: '#444444',
            flex: 1,
            paddingTop: ( isLandscape ? 0 : 6*vh ),
        },

        navDrawerCloseLink: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: 3*vu
        },
        navDrawerCloseText: {
            marginLeft: 1*vu,
            color: colors.action
        },

        navDrawerLink: {
            padding: 3*vu,
        },
        navDrawerText: {
            color: colors.action
        },

        navDrawerLinks: {
            margin: 2*vu,
            marginTop: 0,
            borderColor: colors.action,
            borderTopWidth: 0.1*vu,
        },

        homeIntro: {
            margin: ( isLandscape ? 6*vu : 12*vu ),
        },
        homeIntroFirstNames: {
            color: colors.gold,
            fontSize: ( isLandscape ? 6*em : 7.5*em ),
            fontFamily: 'Tangerine_400Regular',
            textAlign: 'center'
        },
        homeIntroLastName: {
            color: colors.gold,
            fontSize: ( isLandscape ? 2.5*em : 3*em ),
            fontFamily: 'Montserrat_500Medium',
            textTransform: 'uppercase',
            textAlign: 'center'
        },
        homeIntroLastNameConnaghan: {
            letterSpacing: 1*vu,
            marginLeft: 1*vu
        },
        homeIntroLastNameNolte: {
            letterSpacing: 3*vu,
            marginLeft: 4*vu
        },
        homeIntroAnd: {
            marginTop: ( isLandscape ? 2*vu : 3*vu ),
            marginBottom: ( isLandscape ? 2*vu : 3*vu ),
            color: colors.gold,
            fontSize: ( isLandscape ? 3*em : 5*em ),
            fontFamily: 'PlayfairDisplay_400Regular_Italic',
            textAlign: 'center'
        },

        homeSection: {
            flexDirection: ( isLandscape ? 'row' : 'column' ),
            justifyContent: 'center',
            paddingTop: ( isLandscape ? 6*vu : 12*vu ),
            paddingBottom: ( isLandscape ? 6*vu : 12*vu ),
        },
        homeHeader: {
            width: ( isLandscape ? 20*vw : 'auto' ),
            justifyContent: 'flex-start'
        },
        homeHeaderText: {
            fontSize: ( isLandscape ? 1*em : 2*em ),
            color: colors.gold,
            fontFamily: 'Montserrat_500Medium',
            textTransform: 'uppercase',
            textAlign: ( isLandscape ? 'right' : 'center' )
        },
        homeHeaderAnd: {
            fontFamily: 'PlayfairDisplay_400Regular_Italic',
        },

        homeContent: {
            width: ( isLandscape ? 40*vw : 'auto' ),
            marginTop: ( isLandscape ? 0 : 6*vu ),
            marginLeft: ( isLandscape ? 4*vw : 8*vu ),
            marginRight: ( isLandscape ? 0 : 8*vu ),
        },
        homeBodyScript: {
            fontFamily: 'Tangerine_400Regular',
            fontSize: ( isLandscape ? 3*em : 6*em ),
            textAlign: ( isLandscape ? 'left' : 'center' ),
            color: colors.gold,
        },
        homeBodySansBold: {
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 1*em : 2.25*em ),
            color: colors.gold,
        },
        homeBodySansMedium: {
            fontFamily: 'Montserrat_500Medium',
            fontSize: ( isLandscape ? 1*em : 2.25*em ),
            color: colors.gold,
        },
        homeBodySansLight: {
            fontFamily: 'Montserrat_300Light',
            fontSize: ( isLandscape ? 1*em : 2.25*em ),
            textAlign: ( isLandscape ? 'left' : 'center' ),
            color: colors.gold,
        },
        homeBodySansLightSmall: {
            fontFamily: 'Montserrat_300Light',
            fontSize: ( isLandscape ? 0.75*em : 2*em ),
            textAlign: ( isLandscape ? 'left' : 'center' ),
            color: colors.gold,
        },
        homeBodySansLightUnderline: {
            fontFamily: 'Montserrat_300Light',
            fontSize: ( isLandscape ? 1*em : 3*em ),
            color: colors.gold,
            textDecorationStyle: 'solid',
            textDecorationLine: 'underline',
        },
        borderButton: {
            color: colors.gold,
            borderRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 2*vu,
            width: ( isLandscape ? 24*vu : 50*vu ),
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },
        borderButtonText: {
            fontFamily: 'Montserrat_700Bold',
            color: colors.gold,
            textAlign: 'center',
            fontSize: ( isLandscape ? 0.75*em : 2*em ),
        },

        homeBodyActionSection: {
            flexDirection: ( isLandscape ? 'row' : 'column' ),
            marginBottom: ( isLandscape ? 2*vu : 8*vu ),
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        homeBodyActionInfo: {
            justifyContent: 'center',
            marginBottom: ( isLandscape ? 0 : 2*vu ),
            marginRight: ( isLandscape ? 2*vu : 0 )
        },
        homeBodyActionInfoText: {
            textAlign: ( isLandscape ? 'left' : 'center' ),
            maxWidth: ( isLandscape ? 22*vw : 'auto' )
        },

        homeBodyScheduleSection: {
            flexDirection: 'column',
            marginBottom: ( isLandscape ? 2*vu : 8*vu ),
            alignItems: 'flex-start'
        },
        homeBodySchedule: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: ( isLandscape ? 2*vu : 8*vu ),
            width: '100%'
        },
        homeBodyScheduleTime: {
            width: ( isLandscape ? '20%' : '30%' ),
        },
        homeBodyScheduleInfo: {
            width: ( isLandscape ? '70%' : '60%' ),
        },
        homeBodyScheduleText: {
            textAlign: 'left'
        },

        homeAirportSection: {
            marginBottom: ( isLandscape ? 2*vu : 6*vu ),
        },
        homeAirportIconSection: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: ( isLandscape ? 0.5*vu : 2*vu ),
            alignSelf: ( isLandscape ? 'flex-start' : 'center' ),
        },
        homeAirportCode: {
            fontSize: ( isLandscape ? 1.5*em : 3*em ),
            marginLeft: 0.5*vu,
            color: 'rgba(235,206,158,0.6)'
        },
        homeAirportName: {
            textAlign: ( isLandscape ? 'left' : 'center' ),
        },

        photosRow: {
            flexDirection: ( isLandscape ? 'row' : 'column' ),
            flexWrap: 'wrap'
        },
        photosImage: {
            width: ( isLandscape ? 12.5*vw : 80*vw ),
            height: ( isLandscape ? 12.5*vw : 80*vw ),
            alignSelf: ( isLandscape ? 'auto' : 'center' ),
            marginRight: 0.5*vw,
            marginBottom: ( isLandscape ? 1.5*vw : 6*vw ),
            borderRadius: 1*vw,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
        },
        photosBorderButton: {
            borderRadius: 1*vw,
            width: ( isLandscape ? 10*vw : 20*vw ),
            height: ( isLandscape ? 10*vw : 20*vw ),
        },
        photosText: {
            width: ( isLandscape ? 25*vw : 'auto' ),
            marginBottom: ( isLandscape ? 0 : 6*vw ),
        },

        partyRow: {
            flexDirection: ( isLandscape ? 'row' : 'column' ),
        },
        partyBox: {
            width: ( isLandscape ? 20*vw : 50*vw ),
            marginTop: ( isLandscape ? 0 : 6*vw ),
            marginRight: 2*vw,
            marginBottom: ( isLandscape ? 2*vw : 0 ),
            flexDirection: ( isLandscape ? 'column' : 'row' ),
        },
        partyImage: {
            width: ( isLandscape ? 20*vw : 30*vw ),
            height: ( isLandscape ? 20*vw : 30*vw ),
            borderRadius: 1*vw,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            marginBottom: ( isLandscape ? 1*vw : 0 ),
            marginRight: ( isLandscape ? 0 : 3*vw ),
        },
        partyTextBox: {

        },
        partyText: {
            textAlign: ( isLandscape ? 'center' : 'left' ),
        },
        attireRow: {
            flexDirection: 'row',
            marginBottom: ( isLandscape ? 2*vu : 6*vu ),
            textAlign: 'left'
        },
        attireIcon: {
            marginRight: 2*vu,
            width: 40
        },
        attireText: {
            textAlign: 'left',
            width: 60*vw
        },


        modalContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: 100*vw,
            height: 100*vh,
            padding: ( isLandscape ? 10*vw : 2*vw ),
            paddingTop: 5*vh,
            paddingBottom: 5*vh,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 1000
        },
        modalHidden: {
            display: 'none',
            opacity: 0
        },
        modalView: {
            backgroundColor: '#444444',
            borderRadius: ( isLandscape ? 2*vw : 2*vh ),
            padding: ( isLandscape ? 2*vw : 2*vh ),
            width: '100%',
            height: '100%',
            alignItems: "flex-start",
            shadowColor: "#000000",
            shadowOffset: {
                width: 0,
                height: 0
            },
            shadowOpacity: 1,
            shadowRadius: 3*vu,
            elevation: 2,
        },
        modalTop: {
            alignSelf: 'stretch'
        },
        modalClose: {
            position: 'absolute',
            top: 0,
            right: 0
        },
        modalScroll: {},
        modalHeader: {
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 1*em : 2.25*em ),
            color: colors.gold,
            textAlign: 'left',
            width: 60*vw
        },
        modalByline: {
            fontFamily: 'Montserrat_300Light',
            fontSize: ( isLandscape ? 0.75*em : 2*em ),
            color: colors.gold,
            marginBottom: ( isLandscape ? 2*vu : 6*vu ),
            textAlign: 'left',
            width: 60*vw
        },
        modalParagraph: {
            fontFamily: 'Montserrat_300Light',
            fontSize: ( isLandscape ? 1*em : 2.25*em ),
            color: colors.gold,
            marginBottom: ( isLandscape ? 2*vu : 6*vu ),
            textAlign: 'left'
        },
        photoModalClose: {
            position: 'absolute',
            top: 5*vh,
            right: 5*vw
        },

        photosContent: {
            width: ( isLandscape ? 75*vw : 'auto' )
        },

        respondHeader: {
            color: colors.gold,
            fontSize: ( isLandscape ? 2.5*em : 3*em ),
            fontFamily: 'Montserrat_500Medium',
            textTransform: 'uppercase',
            textAlign: 'center'
        },
        respondInputRow: {
            flexDirection: 'row',
        },
        respondTextInput: {
            color: colors.gold,
            borderTopLeftRadius: 1*vu,
            borderBottomLeftRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 2*vu,
            width: ( isLandscape ? 30*vw : 80*vw ),
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },
        respondTextButton: {
            borderTopRightRadius: 1*vu,
            borderBottomRightRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            backgroundColor: colors.gold,
            padding: 2*vu,
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },
        respondTextButtonText: {
            fontFamily: 'Montserrat_700Bold',
            color: colors.slate,
            textAlign: 'center',
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
        },
        respondLoading: {
            paddingTop: ( isLandscape ? 6*vu : 12*vu ),
            paddingBottom: ( isLandscape ? 6*vu : 12*vu )
        },
        respondLoadingText: {
            textAlign: 'center',
            paddingLeft: ( isLandscape ? 6*vu : 12*vu ),
            paddingRight: ( isLandscape ? 6*vu : 12*vu ),
        },
        respondSelect: {
            color: colors.gold,
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            borderTopLeftRadius: 1*vu,
            borderBottomLeftRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 1.9*vu,
            width: ( isLandscape ? 30*vw : 80*vw ),
            backgroundColor: colors.slate,
        },
        respondSelectOption: {
            color: colors.gold,
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 0.75*em : 2*em ),
            backgroundColor: colors.slate,
        },
        respondGuestName: {
            fontSize: ( isLandscape ? 3*em : 6*em ),
            color: colors.gold,
            fontFamily: 'Tangerine_400Regular',
            textAlign: ( isLandscape ? 'right' : 'center' )
        },
        respondGuestSection: {
            flexDirection: 'row',
            justifyContent: ( isLandscape ? 'center' : 'flex-end' ),
            paddingTop: ( isLandscape ? 3*vu : 6*vu ),
        },
        respondGuestAnswer: {
            flexDirection: 'row',
            justifyContent: ( isLandscape ? 'flex-start' : 'flex-end' ),
            width: 40*vw,
            marginTop: ( isLandscape ? 0 : 0 ),
        },
        respondGuestAccept: {
            borderTopLeftRadius: 1*vu,
            borderBottomLeftRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 2*vu,
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },
        respondGuestDecline: {
            borderTopRightRadius: 1*vu,
            borderBottomRightRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 2*vu,
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },
        respondGuestAnswerText: {
            color: colors.gold,
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            fontFamily: 'Montserrat_700Bold',
            textAlign: 'center'
        },
        respondCommentsInput: {
            color: colors.gold,
            borderRadius: 1*vu,
            borderColor: colors.gold,
            borderWidth: 0.25*vu,
            padding: 2*vu,
            marginBottom: 2*vu,
            width: ( isLandscape ? 30*vw : 80*vw ),
            fontFamily: 'Montserrat_700Bold',
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            alignSelf: ( isLandscape ? 'flex-start' : 'center' )
        },

        respondGuestAnswerYes: {
            borderColor: colors.green,
        },
        respondGuestAnswerYesText: {
            color: colors.green,
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            fontFamily: 'Montserrat_700Bold',
            textAlign: 'center'
        },
        respondGuestAnswerNo: {
            borderColor: colors.red,
        },
        respondGuestAnswerNoText: {
            color: colors.red,
            fontSize: ( isLandscape ? 1.2*em : 2*em ),
            fontFamily: 'Montserrat_700Bold',
            textAlign: 'center'
        },

        pageWatchFrame: {
            marginTop: 10*vh,
            backgroundColor: '#000000',
            alignItems: 'center'
        }
    });
}
