import React, { useEffect } from 'react';
import {
    Text,
    View,
    ScrollView,
    KeyboardAvoidingView,
    TouchableOpacity,
    BackHandler,
    Platform,
    useWindowDimensions,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import globalStyles from '../../styles/Global';

export default function GenericPage( props ) {

    const styles = globalStyles( useWindowDimensions() );

    const avoidBehavior = Platform.OS == "ios" ? "padding" : "height";

    return (
        <LinearGradient
            colors={['#444444', '#222222']}
            start={[0,0]}
            end={[0,1]}
            locations={[0,1]}
            style={ styles.page }
            >
            <KeyboardAvoidingView behavior={ avoidBehavior } style={ styles.page }>
                { props.children }
            </KeyboardAvoidingView>
        </LinearGradient>
    );
}
