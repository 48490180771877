import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    Image,
    useWindowDimensions,
    TouchableOpacity
} from 'react-native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import globalStyles from '../../styles/Global';

export default function HeaderButtons( props ) {

    const styles = globalStyles( useWindowDimensions() );

    return (
        <View style={ styles.headerIconsWrapper }>
            <TouchableOpacity style={ styles.headerIconButton } onPress={ () => { props.navigation.navigate( 'Home' ); } }>
                <View style={ styles.headerIconRow }>
                    <MaterialCommunityIcons name="script-text" size={ 24 } color={ props.route.name === 'Main' ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.6)" } />
                    <Text style={[ styles.headerIconText, ( props.route.name === 'Main' ? styles.headerIconTextCurrent : {} ) ]}>Home</Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity style={ styles.headerIconButton } onPress={ () => { props.navigation.navigate( 'Photos' ); } }>
                <View style={ styles.headerIconRow }>
                    <MaterialIcons name="photo-library" size={ 24 } color={ props.route.name === 'Photos' ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.6)" } />
                    <Text style={[ styles.headerIconText, ( props.route.name === 'Photos' ? styles.headerIconTextCurrent : {} ) ]}>Photos</Text>
                </View>
            </TouchableOpacity>
            <TouchableOpacity style={ styles.headerIconButton } onPress={ () => { props.navigation.navigate( 'Watch' ); } }>
                <View style={ styles.headerIconRow }>
                    <MaterialIcons name="live-tv" size={ 24 } color={ props.route.name === 'Watch' ? "rgba(255,255,255,1)" : "rgba(255,255,255,0.6)" } />
                    <Text style={[ styles.headerIconText, ( props.route.name === 'Watch' ? styles.headerIconTextCurrent : {} ) ]}>Watch</Text>
                </View>
            </TouchableOpacity>
{/*
            <TouchableOpacity style={ styles.headerIcon } onPress={ () => {} }>
                <MaterialIcons name="message" size={ 24 } color="rgba(255,255,255,0.6)" />
                <Text style={ styles.headerIconText }>Chat</Text>
            </TouchableOpacity>
*/}
        </View>
    );
}
