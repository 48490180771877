import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    useWindowDimensions,
    Platform,
    Modal,
    Pressable
} from 'react-native';

import { MaterialCommunityIcons, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import GenericPage from '../../components/GenericPage/GenericPage';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import globalStyles from '../../styles/Global';
import ImageViewer from 'react-native-image-zoom-viewer';

const images = [
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-100.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-101.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-102.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-104.jpg', w: 1 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-105.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-106.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-107.jpg', w: 0.789 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-108.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-109.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-110.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-112.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-118.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-120.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-121.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-122.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-124.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-125.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-126.jpg', w: 1.5 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-128.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-130.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-131.jpg', w: 0.667 },
    { url: 'https://katiemicah.s3.amazonaws.com/engagement/ArtistFavorites-129.jpg', w: 1.5 },

    { url: 'https://katiemicah.s3.amazonaws.com/dating/2016-10-29-vegas.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2017-11-11-wurstfest.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2017-12-23-lights.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2018-09-22-joseph.jpg', w: 0.884 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2018-09-29-pizza.jpg', w: 0.892 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2019-07-07-hawaii.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2019-12-15-birthday.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2020-02-28-bastrop.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2020-03-08-costarica.jpg', w: 1.333 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2020-04-11-zoomcall.jpg', w: 0.750 },
    { url: 'https://katiemicah.s3.amazonaws.com/dating/2020-12-20-nashville.jpg', w: 1.333 },

];

export default function PhotosScreen({ navigation }) {

    const window = useWindowDimensions();
    const styles = globalStyles( window );
    const vh = window.height * 0.01;
    const vw = window.width * 0.01;
    const isLandscape = ( vh < vw );

    const [ isPhotosVisible, setIsPhotosVisible ] = React.useState( false );
    const [ photosIndex, setPhotosIndex ] = React.useState( 0 );

    const showPhotos = ( index ) => {
        if ( Platform.OS === 'web' ) return;
        setPhotosIndex( index );
        setIsPhotosVisible( true );
    }

    return (
        <GenericPage>

            <ScrollView
                style={ styles.pageScrollView }
                keyboardShouldPersistTaps="always"
                >

                <View style={ styles.pageContent }>

                    <View style={ styles.homeIntro }>

                        <Text style={ styles.homeIntroFirstNames }>Photos</Text>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Engagement
                            </Text>
                        </View>

                        <View style={[ styles.homeContent, styles.photosContent ]}>
                            <View style={ styles.photosRow }>
                                { images.slice( 0, 22 ).map( ( image, i ) =>
                                    <Pressable key={ i } onPress={ () => { showPhotos( i ) } }>
                                        <Image source={{ uri: image.url }} style={[ styles.photosImage, {
                                            width: ( isLandscape ? image.w*20*vw : image.w*50*vw ),
                                            height: ( isLandscape ? 20*vw : 50*vw )
                                        } ]} />
                                    </Pressable>
                                )}
                            </View>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Dating
                            </Text>
                        </View>

                        <View style={[ styles.homeContent, styles.photosContent ]}>
                            <View style={ styles.photosRow }>
                                { images.slice( 22, 33 ).map( ( image, i ) =>
                                    <Pressable key={ i } onPress={ () => { showPhotos( i+22 ) } }>
                                        <Image source={{ uri: image.url }} style={[ styles.photosImage, {
                                            width: ( isLandscape ? image.w*20*vw : image.w*50*vw ),
                                            height: ( isLandscape ? 20*vw : 50*vw )
                                        } ]} />
                                    </Pressable>
                                )}
                            </View>
                        </View>

                    </View>

                    <HorizontalRule />

                </View>

            </ScrollView>

            { Platform.OS !== 'web' && (
                <Modal
                    visible={ isPhotosVisible }
                    transparent={ true }
                    onRequestClose={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } }
                    supportedOrientations={
                        [
                            'portrait',
                            'portrait-upside-down',
                            'landscape',
                            'landscape-left',
                            'landscape-right'
                        ]
                    }>
                    <ImageViewer imageUrls={ images } index={ photosIndex } onCancel={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } } enableSwipeDown={true} />
                    <TouchableOpacity style={ styles.photoModalClose } onPress={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } }>
                        <MaterialCommunityIcons name="close-circle" size={ 36 } color="rgba(235,206,158,0.6)" />
                    </TouchableOpacity>
                </Modal>
            )}

        </GenericPage>
    );
}
