import React, { useState, useEffect, useRef } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    SafeAreaView,
    useWindowDimensions,
    Linking,
    Platform,
    Modal,
    Pressable
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons, FontAwesome5, MaterialIcons } from '@expo/vector-icons';
import ImageViewer from 'react-native-image-zoom-viewer';

import GenericPage from '../../components/GenericPage/GenericPage';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import globalStyles from '../../styles/Global';

const images = [
    {
        url: 'https://katiemicah.s3.amazonaws.com/home/intro1.jpg'
    },
    {
        url: 'https://katiemicah.s3.amazonaws.com/home/intro2.jpg'
    },
    {
        url: 'https://katiemicah.s3.amazonaws.com/home/intro3.jpg'
    }
];

export default function HomeScreen( props ) {

    const styles = globalStyles( useWindowDimensions() );

    const elements = useRef({
        katiemicah: 0,
        datetime: 0,
        location: 0,
        story: 0,
        photos: 0,
        covid: 0,
        hotels: 0,
        travel: 0,
        austin: 0,
        schedule: 0,
        weddingparty: 0,
        registry: 0,
    });
    const scrollViewRef = useRef();

    const [ isHisStoryVisible, setIsHisStoryVisible ] = React.useState( false );
    const [ isHerStoryVisible, setIsHerStoryVisible ] = React.useState( false );
    const [ isTrueStoryVisible, setIsTrueStoryVisible ] = React.useState( false );
    const [ isPhotosVisible, setIsPhotosVisible ] = React.useState( false );
    const [ photosIndex, setPhotosIndex ] = React.useState( 0 );

    useEffect(() => {
        if ( !global.km ) global.km = {};
        global.km.navClick = ( ident ) => {
            props.navigation.closeDrawer();
            scrollViewRef.current.scrollTo({ x: 0, y: elements.current[ ident ], animated: true });
        }
        return () => {
            global.km.navClick = () => {};
        }
    }, []);

    const handlePress = () => {
        props.navigation.openDrawer();
    }

    const handleLayout = ( sectionName, e ) => {
        let clonedElements = JSON.parse( JSON.stringify( elements.current ) );
        clonedElements[ sectionName ] = e.nativeEvent.layout.y + 80;
        elements.current = clonedElements;
    }

    const showHisStory = () => {
        props.hideHeaderButtons();
        setIsHisStoryVisible( true );
    }

    const showHerStory = () => {
        props.hideHeaderButtons();
        setIsHerStoryVisible( true );
    }

    const showTrueStory = () => {
        props.hideHeaderButtons();
        setIsTrueStoryVisible( true );
    }

    const closeHisStory = () => {
        props.showHeaderButtons();
        setIsHisStoryVisible( false );
    }

    const closeHerStory = () => {
        props.showHeaderButtons();
        setIsHerStoryVisible( false );
    }

    const closeTrueStory = () => {
        props.showHeaderButtons();
        setIsTrueStoryVisible( false );
    }

    const showPhotos = ( index ) => {
        if ( Platform.OS === 'web' ) return;
        setPhotosIndex( index );
        setIsPhotosVisible( true );
    }

    return (
        <GenericPage>

            <ScrollView
                ref={ scrollViewRef }
                style={ styles.pageScrollView }
                keyboardShouldPersistTaps="always"
                >

                <View style={ styles.pageContent }>

                    <View style={ styles.homeIntro }>

                        <Text style={ styles.homeIntroFirstNames }>Kathryn Blair</Text>
                        <Text style={[ styles.homeIntroLastName, styles.homeIntroLastNameConnaghan ]}>Connaghan</Text>
                        <Text style={ styles.homeIntroAnd }>&</Text>
                        <Text style={ styles.homeIntroFirstNames }>Micah Isaac</Text>
                        <Text style={[ styles.homeIntroLastName, styles.homeIntroLastNameNolte ]}>Nolte</Text>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'datetime' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Date <Text style={ styles.homeHeaderAnd }>&</Text> Time
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={ styles.homeBodyScript }>October 9th, 2021</Text>
                            <Text style={ styles.homeBodySansLight }>Ceremony Begins 5:00 PM</Text>
                            <Text style={[ styles.paragraph, styles.homeBodySansLight ]}>Dinner, drinks, and dancing to follow</Text>
                            <TouchableOpacity style={ styles.borderButton } onPress={ () => { props.navigation.navigate( 'Watch' ); } }>
                                <Text style={ styles.borderButtonText }>Watch Stream Here</Text>
                            </TouchableOpacity>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'location' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Location
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={ styles.homeBodyScript }>The Riley Building</Text>
                            <Text style={ styles.homeBodySansLight }>315 Lavaca Street</Text>
                            <Text style={[ styles.paragraph, styles.homeBodySansLight ]}>Austin, Texas 78701</Text>
                            <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                let url = 'https://www.google.com/maps/place/The+Riley+Building/@30.2663958,-97.74811,17z/data=!3m1!4b1!4m5!3m4!1s0x8644b55b59ecf095:0x71f5537fd07571a4!8m2!3d30.2664065!4d-97.7459566';
                                if ( Platform.OS === 'web' ) {
                                   window.open( url, '_blank' );
                                } else {
                                   Linking.openURL( url );
                                }
                            } }>
                                <Text style={ styles.borderButtonText }>Open in Google Maps</Text>
                            </TouchableOpacity>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'story' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Story
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>They say there's three sides to every story: his side, her side, and the truth.</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>His Story</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>by Micah</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>From the moment we first met, it started with a bang...</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ showHisStory }>
                                    <Text style={ styles.borderButtonText }>Read His Story</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Her Story</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>by Katie</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>It was October 24th, 2014 (1024, which I came to find out was a “great number because in binary equates to two to the tenth power” **Mind explodes**)...</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ showHerStory }>
                                    <Text style={ styles.borderButtonText }>Read Her Story</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>The Truth</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>by a totally neutral and unbiased person</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>I couldn't tell you the exact weekend date, but I can tell you it was a crisp, January evening in 2015 and I was patiently awaiting Katie and Stefani's arrival...</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ showTrueStory }>
                                    <Text style={ styles.borderButtonText }>Read The Truth</Text>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'photos' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Photos
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <View style={ styles.photosRow }>
                                <Pressable onPress={ () => { showPhotos( 0 ) } }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/intro1.jpg' }} style={ styles.photosImage } />
                                </Pressable>
                                <Pressable onPress={ () => { showPhotos( 1 ) } }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/intro2.jpg' }} style={ styles.photosImage } />
                                </Pressable>
                                <Pressable onPress={ () => { showPhotos( 2 ) } }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/intro3.jpg' }} style={ styles.photosImage } />
                                </Pressable>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText, styles.photosText ]}>If you like seeing pictures of the same two people, then head on over to the Photos section...</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => { props.navigation.navigate( 'Photos' ); } }>
                                    <Text style={ styles.borderButtonText }>See All Photos</Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'covid' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                COVID Policy
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Due to the ongoing pandemic (how is this not over yet?!?), we’d like to assure you that making this a safe event, as well as an incredibly fun one, is a top priority to us. In order to do so, we are asking all guests who are attending our wedding to please be fully vaccinated prior to the wedding ceremony and reception.</Text>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>If vaccination is not possible (and we understand it may not be), we ask that you please take a COVID test 48-72 hours prior to our wedding. Rapid Testing is available at Tarrytown Pharmacy, linked below.</Text>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Additionally, the following safety measures will be in place for the duration of Saturdays event in order to ensure the safety of all guests and vendors:</Text>

                            <View style={ styles.bulletRow }>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph, styles.indent ]}>●</Text>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>All catering staff will be masked throughout the wedding and reception</Text>
                            </View>

                            <View style={ styles.bulletRow }>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph, styles.indent ]}>●</Text>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Plexiglass barriers will be in place for buffet and bars to protect vendors and guests</Text>
                            </View>

                            <View style={ styles.bulletRow }>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph, styles.indent ]}>●</Text>
                                <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Most of the event will be held outdoors, with the exception of the cocktails and seated dinner</Text>
                            </View>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>We truly cannot wait to spend this incredible day with you, and are thankful for your support always. See you soon!</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Tarrytown Pharmacy</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>101 W 3rd St</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Rapid COVID Testing</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://hipaa.jotform.com/Tarrytown_Pharmacy/the-central-site-Point-Of-Care-Test';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Book Appointment</Text>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'hotels' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Hotels
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Downtown has no shortage of hotel choices, but here are a few options we recommend.</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>JW Marriott Austin</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>(0.3 miles)</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Party with us!</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.kayak.com/hotels/JW-Marriott-Austin,Austin-c22863-h787553-details/2021-10-08/2021-10-10';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Open in Kayak</Text>
                                </TouchableOpacity>
                            </View>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph, styles.subnote ]}>The Bride and Groom will be staying at the JW Marriott in Downtown Austin. We'd love for you to join us (in your own separate room, of course).</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Austin Proper Hotel</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>(0.3 miles)</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>So swanky!</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.kayak.com/hotels/Austin-Proper-Hotel,-a-Member-of-Design-HotelsTM,Austin-c22863-h903641-details/2021-10-08/2021-10-10';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Open in Kayak</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Hilton Austin</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>(0.5 miles)</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Gotta use dem points!</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.kayak.com/hotels/Hilton-Austin,Austin-c22863-h40808-details/2021-10-08/2021-10-10';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Open in Kayak</Text>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'travel' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Travel
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>

                            <View style={ styles.homeAirportSection }>
                                <View style={ styles.homeAirportIconSection }>
                                    <MaterialCommunityIcons name="airplane" size={ 36 } color="rgba(235,206,158,0.6)" />
                                    <Text style={[ styles.homeBodySansBold, styles.homeAirportCode ]}>AUS</Text>
                                </View>
                                <Text style={[ styles.homeBodySansMedium, styles.homeAirportName ]}>Austin-Bergstrom</Text>
                                <Text style={[ styles.homeBodySansMedium, styles.homeAirportName ]}>International Airport</Text>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Downtown is about a 20 minute Uber or Lyft ride from the airport.</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'http://www.austintexas.gov/sites/default/files/images/Airport/maps/print_directions_taxi_ride-app.pdf';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Ride Share Pickup Location in Airport</Text>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'schedule' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Schedule
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>

                            <View style={ styles.homeBodyScheduleSection }>

                                <View style={ styles.homeBodySchedule }>
                                    <View style={ styles.homeBodyScheduleTime }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Thursday</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>7pm – 9pm</Text>
                                    </View>
                                    <View style={ styles.homeBodyScheduleInfo }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Welcome Drinks</Text>
                                        <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyScheduleText ]}>Central Machine Works</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>We can't wait to welcome you to Austin.</Text>
                                    </View>
                                </View>

                                <View style={ styles.homeBodySchedule }>
                                    <View style={ styles.homeBodyScheduleTime }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Friday</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>All Day</Text>
                                    </View>
                                    <View style={ styles.homeBodyScheduleInfo }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Enjoy Austin!</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>Live large, eat tacos, etc...</Text>
                                    </View>
                                </View>

                                <View style={ styles.homeBodySchedule }>
                                    <View style={ styles.homeBodyScheduleTime }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Saturday</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>5pm – 10:30pm</Text>
                                    </View>
                                    <View style={ styles.homeBodyScheduleInfo }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>The Wedding</Text>
                                        <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyScheduleText ]}>The Riley Building</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>The big day!</Text>
                                    </View>
                                </View>
{/*
                                <View style={ styles.homeBodySchedule }>
                                    <View style={ styles.homeBodyScheduleTime }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Sunday</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>10am – Noonish</Text>
                                    </View>
                                    <View style={ styles.homeBodyScheduleInfo }>
                                        <Text style={[ styles.homeBodySansBold, styles.homeBodyScheduleText ]}>Farewell</Text>
                                        <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyScheduleText ]}>JW Marriott Pool, 5th Floor</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.homeBodyScheduleText ]}>Bid Katie & Micah adieu before your flight out.</Text>
                                    </View>
                                </View>
*/}
                            </View>

                        </View>
                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'weddingparty' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Wedding Party
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <View style={ styles.partyRow }>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/libby.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Libby Broom</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Maid of Honor</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.partyText ]}>Due to the baby's imminent arrival, Libby will be resting at home, but will be there in spirit.</Text>
                                    </View>
                                </View>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/billy.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Billy Watson</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Best Man</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.partyText ]}>Cousin of the Groom</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={ styles.partyRow }>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/sam.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Sam Mooney</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Bridesman</Text>
                                    </View>
                                </View>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/stef.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Stef Jenness</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Bridesmaid</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={ styles.partyRow }>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/pippa.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Pippa Broom</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Flower Girl</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.partyText ]}>Fashionista</Text>
                                    </View>
                                </View>
                                <View style={ styles.partyBox }>
                                    <Image source={{ uri: 'https://katiemicah.s3.amazonaws.com/home/ann.jpg' }} style={ styles.partyImage } />
                                    <View style={ styles.partyTextBox }>
                                        <Text style={[ styles.homeBodyScript, styles.partyText ]}>Ann Schneider</Text>
                                        <Text style={[ styles.homeBodySansMedium, styles.partyText ]}>Officiant</Text>
                                        <Text style={[ styles.homeBodySansLight, styles.partyText ]}>Soul Sister to the Bride</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'registry' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Registry
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Your presence is more than we could hope for on our wedding weekend, so please no gifts. If you'd like to help us celebrate, please consider donating to one of the below charities instead!</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Austin Pets Alive!</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.austinpetsalive.org/donate/donate-now';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Donate</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>St. Jude Children's Hospital</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.stjude.org/donate/donate-to-st-jude.html?frequency_selected=3&tributeeFirstName=Katie%20%26%20Micah&tributeeLastName=Connaghan%20%26%20Nolte&notifyFirstName=Katie%20%26%20Micah&notifyLastName=Connaghan%20%26%20Nolte&emailRec=holler%40micahnolte.com';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Donate</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Heifer International</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.heifer.org/give/giving-in-honor.html';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Donate</Text>
                                </TouchableOpacity>
                            </View>

                            <Text style={[ styles.homeBodySansLight, styles.paragraph, styles.subnote ]}>In lieu of party gifts, we will be making a donation to the above organizations with those funds.</Text>

                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'dressattire' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Dress Attire
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <View style={ styles.attireRow }>
                                <View style={ styles.attireIcon }>
                                    <FontAwesome5 name="user-tie" size={ 30 } color="rgba(235,206,158,0.6)" />
                                </View>
                                <Text style={[ styles.homeBodySansLight, styles.attireText ]}>Officially, this is a “Formal Attire” Event. That means you should leave your blue jeans at the hotel.</Text>
                            </View>
                            <View style={ styles.attireRow }>
                                <View style={ styles.attireIcon }>
                                    <MaterialIcons name="masks" size={ 30 } color="rgba(235,206,158,0.6)" />
                                </View>
                                <Text style={[ styles.homeBodySansLight, styles.attireText ]}>While we are hopeful for the state of the world (and Austin) in October, we can't guarantee anything, so we recommend that you pack a mask, just in case.</Text>
                            </View>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'adultsonly' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Adults Only
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={ styles.homeBodySansLight }>We love your kids. We really do. Just not this night.</Text>
                        </View>

                    </View>

                    <HorizontalRule />

                    <View style={ styles.homeSection } onLayout={ handleLayout.bind( null, 'austin' ) }>

                        <View style={ styles.homeHeader }>
                            <Text style={ styles.homeHeaderText }>
                                Things To Do in Austin
                            </Text>
                        </View>

                        <View style={ styles.homeContent }>
                            <Text style={[ styles.homeBodySansLight, styles.paragraph ]}>Austin is an amazing city! Here's a few ideas to help you enjoy it...</Text>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Bat Watching</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>Congress Street Bridge</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>See the bats fly out at sunset</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.austintexas.org/things-to-do/outdoors/bat-watching/';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Bat Watching Guide</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Austin City Limits Festival</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>Zilker Park</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>This is the big music festival</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.aclfestival.com/';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>ACL Festival</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Live Music</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>Any Bar</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>The reason Austin's the Live Music Capital</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://showlistaustin.com/';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Showlist Austin</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Eat Barbecue</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>It's the Texas thing to do</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Cooper's, Terry Black's, la Barbecue</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://goo.gl/maps/vBPLnbuK5WPTtw8z9';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>BBQ Map</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Lady Bird Lake</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>Don't call it a river</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Paddleboards, jogging paths, and boats.</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://thetrailfoundation.org/wp-content/uploads/2018/04/8-5x11_TTF-Web-Printable-Loop-Map.pdf';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>Trail Map (PDF)</Text>
                                </TouchableOpacity>
                            </View>

                            <View style={ styles.homeBodyActionSection }>
                                <View style={ styles.homeBodyActionInfo }>
                                    <Text style={[ styles.homeBodySansBold, styles.homeBodyActionInfoText ]}>Shopping South Congress</Text>
                                    <Text style={[ styles.homeBodySansLightSmall, styles.homeBodyActionInfoText ]}>SoCo is made for walking</Text>
                                    <Text style={[ styles.homeBodySansLight, styles.homeBodyActionInfoText ]}>Allens Boots, Hotel San Jose, Lucy in Disguise</Text>
                                </View>
                                <TouchableOpacity style={ styles.borderButton } onPress={ () => {
                                    let url = 'https://www.austintexas.org/austin-insider-blog/post/around-town-south-congress/';
                                    if ( Platform.OS === 'web' ) {
                                       window.open( url, '_blank' );
                                    } else {
                                       Linking.openURL( url );
                                    }
                                } }>
                                    <Text style={ styles.borderButtonText }>SoCo Attractions</Text>
                                </TouchableOpacity>
                            </View>

                        </View>

                    </View>

                    <HorizontalRule />

                </View>

            </ScrollView>

            <View style={ styles.menuIconWrapper }>
                <TouchableOpacity style={ styles.menuIcon } onPress={ handlePress }>
                    <MaterialCommunityIcons name="menu" size={ 30 } color="rgba(255,255,255,0.6)" />
                </TouchableOpacity>
            </View>

            <View style={[ styles.modalContainer, ( isHisStoryVisible ? {} : styles.modalHidden ) ]}>
                <View style={ styles.modalView }>
                    <View style={ styles.modalTop }>
                        <Text style={ styles.modalHeader }>His Story</Text>
                        <Text style={ styles.modalByline }>by Micah</Text>
                        <TouchableOpacity style={ styles.modalClose } onPress={ closeHisStory }>
                            <MaterialCommunityIcons name="close-circle" size={ 36 } color="rgba(235,206,158,0.6)" />
                        </TouchableOpacity>
                    </View>
                    <ScrollView style={ styles.modalScroll }>
                        <Text style={ styles.modalParagraph }>From the moment we first met, it started with a bang.</Text>
                        <Text style={ styles.modalParagraph }>Katie flew in from Portland for a weekend trip to San Francisco to visit her friend Chloe. They arrived at our office, near the end of a long night of Friday night football. We had an instant connection, and spent a large portion of the weekend together.</Text>
                        <Text style={ styles.modalParagraph }>However, we were both in different places in life (and different cities), so we continued on our own paths. Over the next two years, we would end up hanging out whenever she would take a trip to the Bay Area, and so we grew closer and closer. Eventually, during a trip to Las Vegas with a group, we decided to make it official and enter into a long-distance relationship.</Text>
                        <Text style={ styles.modalParagraph }>I moved back to Texas shortly afterwards in the Silver Burrito, and we stayed in touch with weekly phone calls and several trips between Austin and Portland. And then finally, Katie decided to make the big leap, packing up everything she has and moving to Texas. We bought a house, and started the new life of cohabitation. It was a big learning experience for both of us, as our understanding of each other flourished.</Text>
                        <Text style={ styles.modalParagraph }>We had talked about marriage, and what type of ring she'd want if I did propose. She included Instagram feeds and Pinterest boards in the discussion, so I had a good idea of how to go about it. And finally, the day after Christmas, over 6 years after we met, while sitting on the bunk beds of an Airbnb in Nashville, I proposed to her.</Text>
                        <Text style={ styles.modalParagraph }>She said yes.</Text>
                    </ScrollView>
                </View>
            </View>

            <View style={[ styles.modalContainer, ( isHerStoryVisible ? {} : styles.modalHidden ) ]}>
                <View style={ styles.modalView }>
                    <View style={ styles.modalTop }>
                        <Text style={ styles.modalHeader }>Her Story</Text>
                        <Text style={ styles.modalByline }>by Katie</Text>
                        <TouchableOpacity style={ styles.modalClose } onPress={ closeHerStory }>
                            <MaterialCommunityIcons name="close-circle" size={ 36 } color="rgba(235,206,158,0.6)" />
                        </TouchableOpacity>
                    </View>
                    <ScrollView style={ styles.modalScroll }>

                        <Text style={ styles.modalParagraph }>It was October 24th, 2014 (1024, which I came to find out was a “great number because in binary equates to two to the tenth power” **Mind explodes**), and I’d come to San Francisco to visit a totally neutral and unbiased friend who had recently moved to the city. She wanted us to meet her new boyfriend who she worked with and really liked (side note, now they are expecting a BABY!!), and so we trekked into a tech startup office to meet all her new coworkers. Amongst the techies drinking beer and doing techie things, I caught sight of a cute nerd in the corner and struck up a conversation. But the cards were not aligned, and I flew back to Portland and he back to his $25 million-trillion/month SF apartment and on with our lives.</Text>

                        <Text style={ styles.modalParagraph }>I spent the next two years in love with SF and visiting my neutral and totally unbiased friend, every time not-so-secretly hoping that Micah would emerge from the depths of his trailer and come say hi because #highstandards. The cards finally aligned when a proposed group trip to Vegas had us both happy and drunkinlove and thinking, What better way to start a relationship than in the middle of a casino! After that very sober decision, I flew back to Portland, and he to his trailer, and we took every opportunity to forge a phone-call relationship. Micah moved from SF to Austin, and we spent the next 10 months traveling and adventuring in each other's cities and other people’s cities. And when Micah decided to settle down in Austin and buy a house, my dog Roxie and I decided to leave Portland behind join him in Austin.</Text>

                        <Text style={ styles.modalParagraph }>Micah spent the next 3 years fighting the fact that he might actually be a little bit of a dog person. Roxie spent those 3 years offended. I spent those years learning the art of subtly exchanging “you” for “y’all” and falling in love with the West Texas boy. And on Boxing Day 2020 (don’t call it a Christmas engagement!), in Nashville, Tennessee, Micah proposed on the bunkbeds of our Airbnb and we decided to do this for the rest of our lives.</Text>

                        <Text style={ styles.modalParagraph }>The end (or is the beginning...?)</Text>
                    </ScrollView>
                </View>
            </View>

            <View style={[ styles.modalContainer, ( isTrueStoryVisible ? {} : styles.modalHidden ) ]}>
                <View style={ styles.modalView }>
                    <View style={ styles.modalTop }>
                        <Text style={ styles.modalHeader }>The Truth</Text>
                        <Text style={ styles.modalByline }>by a totally neutral and unbiased person</Text>
                        <TouchableOpacity style={ styles.modalClose } onPress={ closeTrueStory }>
                            <MaterialCommunityIcons name="close-circle" size={ 36 } color="rgba(235,206,158,0.6)" />
                        </TouchableOpacity>
                    </View>
                    <ScrollView style={ styles.modalScroll }>

                        <Text style={ styles.modalParagraph }>I couldn't tell you the exact weekend date, but I can tell you it was a crisp, January evening in 2015 and I was patiently awaiting Katie and Stefani's arrival. This would be the first time they visited me in SF and I was VERY excited for them to meet the man I recently taken as a lover, who was also my boss, who is now my husband and who is also one of Micah's best friends. I knew Stefani would hit it off with gangly co-worker Will, and I was assuming co-worker Emil and Katie would do some light flirting throughout the weekend. BOY. WAS. I. WRONG.</Text>

                        <Text style={ styles.modalParagraph }>After more than a few drinks at a dingy bar in the lower Haight, I looked to my left to find Stefani gobbling up one of Will's lame ass jokes "AND THEN I SAID!!!!" and then I peered to my right where I saw the unfathomable. Micah flirting, flirting with a girl, Micah flirting with Katie! I nudged Eric in disbelief and we looked on as their intense conversation got more and more handsy. I recall them talking about some nerd shit I still don't understand.</Text>

                        <Text style={ styles.modalParagraph }>I think it's safe to say Katie was my most frequent visitor while living in SF! During her stays she would see Micah here and there, but nothing notable would come of their visits until the HALLOWEEN of 2016. I went around our office assessing interest in a potential Halloween trip and vividly remember Micah immediately committing, which I found to be very out of character. I believe he ended up booking all of our hotel rooms within 24 hours. That's when I knew he would love Katie forever and take Roxie in as his own. The trip was a bit of a blur, I recall us prancing around town in our Winnie the Pooh costumes, I believe at some point we were cruising through casinos piled onto an electric scooter, and most importantly, on this trip Katie and Micah became OFFICIAL.</Text>

                        <Text style={ styles.modalParagraph }>The relationship solidified even more when Katie dodged hurricanes on her move out to live with Micah in Austin. While I wasn't there for their engagement in Nashville last December, I can only imagine it was perfect and very much THEM. Micah may have been nervous, stumbling through his words, some subtle hives may have broached his neck. Katie may have had a bursting heart of love for her nerd charming and she may have savored the moment by celebrating with exactly four glasses of champagne (**editors note: it was six**).</Text>

                        <Text style={ styles.modalParagraph }>Their story doesn't end here. In fact, I look forward to authoring it chapter by chapter as time goes on.</Text>
                    </ScrollView>
                </View>
            </View>

            { Platform.OS !== 'web' && (
                <Modal
                    visible={ isPhotosVisible }
                    transparent={ true }
                    onRequestClose={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } }
                    supportedOrientations={
                        [
                            'portrait',
                            'portrait-upside-down',
                            'landscape',
                            'landscape-left',
                            'landscape-right'
                        ]
                    }>
                    <ImageViewer imageUrls={ images } index={ photosIndex } onCancel={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } } enableSwipeDown={true} />
                    <TouchableOpacity style={ styles.photoModalClose } onPress={ () => { if ( isPhotosVisible ) setIsPhotosVisible( false ) } }>
                        <MaterialCommunityIcons name="close-circle" size={ 36 } color="rgba(235,206,158,0.6)" />
                    </TouchableOpacity>
                </Modal>
            )}

        </GenericPage>
    );
}
