import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import globalStyles from '../../styles/Global';

export default function HorizontalRule() {

    const styles = globalStyles( useWindowDimensions() );

    return (
        <LinearGradient
            colors={['rgba(235,206,158,0)', 'rgba(235,206,158,1)', 'rgba(235,206,158,0)']}
            start={[0,0]}
            end={[1,0]}
            locations={[0,0.5,1]}
            style={ styles.hrFaded }
            />
    );
}
