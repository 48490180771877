import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    ScrollView,
    useWindowDimensions,
    Platform,
    Pressable
} from 'react-native';
import YoutubePlayer from "react-native-youtube-iframe";

import GenericPage from '../../components/GenericPage/GenericPage';
import HorizontalRule from '../../components/HorizontalRule/HorizontalRule';
import globalStyles from '../../styles/Global';

export default function WatchScreen({ navigation }) {

    const playerRef = useRef();
    const [ playing, setPlaying ] = useState(false);

    const window = useWindowDimensions();
    const styles = globalStyles( window );
    const vh = window.height * 0.01;
    const vw = window.width * 0.01;
    const isLandscape = ( vh < vw );

    let playerHeight = 90*vh;
    let playerWidth = 90*vh * 1.7777;

    if ( playerWidth > window.width ) {
        playerHeight = 100*vw * 0.5625;
        playerWidth = 100*vw;
    }

    const handleReady = useCallback( (state) => {
        setPlaying( true );
    }, []);

    return (
        <GenericPage>

            <ScrollView
                style={ styles.pageScrollView }
                keyboardShouldPersistTaps="always"
                >

                <View style={ styles.pageWatchFrame }>
                    <YoutubePlayer
                        ref={ playerRef }
                        height={ playerHeight }
                        width={ playerWidth }
                        play={ playing }
                        onReady={ handleReady }
                        initialPlayerParams={ { modestbranding: true } }
                        videoId={ "iimrdhiNmHk" }
                    />
                </View>

            </ScrollView>

        </GenericPage>
    );
}
