import React, { useState, useEffect } from 'react';
import {
    ScrollView,
    View,
    Text,
    Image,
    useWindowDimensions,
    TouchableOpacity
} from 'react-native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

import globalStyles from '../../styles/Global';

export default function NavDrawer( props ) {

    const styles = globalStyles( useWindowDimensions() );

    const handlePress = () => {
        props.navigation.closeDrawer();
    }

    return (
        <View style={ styles.navDrawer }>

            <TouchableOpacity style={ styles.navDrawerCloseLink } onPress={ handlePress }>
                <MaterialCommunityIcons name="close" size={ 30 } color="rgba(255,255,255,0.6)" />
                <Text style={ styles.navDrawerCloseText }>Close Menu</Text>
            </TouchableOpacity>

            <ScrollView style={ styles.navDrawerLinks }>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('katiemicah') } }>
                    <Text style={ styles.navDrawerText }>Katie <Text style={ styles.homeHeaderAnd }>&</Text> Micah</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('datetime') } }>
                    <Text style={ styles.navDrawerText }>Date <Text style={ styles.homeHeaderAnd }>&</Text> Time</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('location') } }>
                    <Text style={ styles.navDrawerText }>Location</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('story') } }>
                    <Text style={ styles.navDrawerText }>Story</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('photos') } }>
                    <Text style={ styles.navDrawerText }>Photos</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('covid') } }>
                    <Text style={ styles.navDrawerText }>COVID Policy</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('hotels') } }>
                    <Text style={ styles.navDrawerText }>Hotels</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('travel') } }>
                    <Text style={ styles.navDrawerText }>Travel</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('schedule') } }>
                    <Text style={ styles.navDrawerText }>Schedule</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('weddingparty') } }>
                    <Text style={ styles.navDrawerText }>Wedding Party</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('registry') } }>
                    <Text style={ styles.navDrawerText }>Registry</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('dressattire') } }>
                    <Text style={ styles.navDrawerText }>Dress Attire</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('adultsonly') } }>
                    <Text style={ styles.navDrawerText }>Adults Only</Text>
                </TouchableOpacity>

                <TouchableOpacity style={ styles.navDrawerLink } onPress={ () => { global.km && global.km.navClick && global.km.navClick('austin') } }>
                    <Text style={ styles.navDrawerText }>Things To Do in Austin</Text>
                </TouchableOpacity>

            </ScrollView>

        </View>
    );
}
