import React, { useEffect, useRef } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Text, View, Button } from 'react-native';
import AppLoading from 'expo-app-loading';
import * as Analytics from 'expo-firebase-analytics';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import {
    useFonts,
    Tangerine_400Regular,
    Tangerine_700Bold
} from '@expo-google-fonts/tangerine';
import {
    Montserrat_300Light,
    Montserrat_500Medium,
    Montserrat_700Bold
} from '@expo-google-fonts/montserrat';
import {
    PlayfairDisplay_400Regular_Italic
} from '@expo-google-fonts/playfair-display';
import { MaterialIcons } from '@expo/vector-icons';

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

import HeaderButtons from './src/components/HeaderButtons/HeaderButtons';
import NavDrawer from './src/components/NavDrawer/NavDrawer';

import HomeScreen from './src/screens/Home/Home';
import PhotosScreen from './src/screens/Photos/Photos';
import WatchScreen from './src/screens/Watch/Watch';

function MainScreen( rootProps ) {
    return (
        <Drawer.Navigator drawerContent={ ( props ) => <NavDrawer {...props} /> }>
            <Drawer.Screen
                name="Home"
                title="Katie & Micah"
            >
                {( props ) => <HomeScreen navigation={ props.navigation } hideHeaderButtons={ rootProps.hideHeaderButtons } showHeaderButtons={ rootProps.showHeaderButtons } /> }
            </Drawer.Screen>
        </Drawer.Navigator>
    );
}

export default function App() {

    let [ fontsLoaded ] = useFonts({
        Tangerine_400Regular,
        Tangerine_700Bold,
        Montserrat_300Light,
        Montserrat_500Medium,
        Montserrat_700Bold,
        PlayfairDisplay_400Regular_Italic
    });

    const [ isHeaderButtonsVisible, setIsHeaderButtonsVisible ] = React.useState( true );

    const navigationRef = useRef();
    const routeNameRef = useRef();

    if ( !fontsLoaded ) return <AppLoading />;

    const hideHeaderButtons = () => {
        setIsHeaderButtonsVisible( false );
    }

    const showHeaderButtons = () => {
        setIsHeaderButtonsVisible( true );
    }

    return (
        <NavigationContainer
            style={{ backgroundColor: '#222222' }}
            ref={ navigationRef }
            onReady={ () =>
                ( routeNameRef.current = navigationRef.current.getCurrentRoute().name )
            }
            onStateChange={ async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute().name;

                if ( previousRouteName !== currentRouteName ) {
                    // The line below uses the expo-firebase-analytics tracker
                    // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
                    // Change this line to use another Mobile analytics SDK
                    await Analytics.logEvent( 'pageview', {
                        screen_name: currentRouteName
                    });
                }

                // Save the current route name for later comparison
                routeNameRef.current = currentRouteName;
            }}>
            <Stack.Navigator
                initialRouteName="Home"
                screenOptions={{
                    headerStyle: { elevation: 0 },
                    cardStyle: { backgroundColor: '#222222' }
                }}>
                <Stack.Screen
                    name="Main"
                    options={ ( props ) => ({
                        title: '',
                        headerTransparent: true,
                        headerRight: ( props2 ) => {
                            if ( !isHeaderButtonsVisible ) return <View />;
                            return <HeaderButtons {...props} />;
                        }
                    })}
                >
                    {( props ) => <MainScreen hideHeaderButtons={ hideHeaderButtons } showHeaderButtons={ showHeaderButtons } /> }
                </Stack.Screen>
                <Stack.Screen
                    name="Photos"
                    options={ ( props ) => ({
                        title: '',
                        headerTransparent: true,
                        headerBackImage: ( props2 ) => {
                            return <MaterialIcons name="arrow-back" size={24} color="rgba(255,255,255,0.6)" />;
                        },
                        headerBackTitleStyle: {
                            color: "rgba(255,255,255,0.6)"
                        },
                        headerRight: ( props2 ) => {
                            return <HeaderButtons { ...props } />;
                        }
                    })}
                >
                    {( props ) => <PhotosScreen /> }
                </Stack.Screen>
                <Stack.Screen
                    name="Watch"
                    options={ ( props ) => ({
                        title: '',
                        headerTransparent: true,
                        headerBackImage: ( props2 ) => {
                            return <MaterialIcons name="arrow-back" size={24} color="rgba(255,255,255,0.6)" />;
                        },
                        headerBackTitleStyle: {
                            color: "rgba(255,255,255,0.6)"
                        },
                        headerRight: ( props2 ) => {
                            return <HeaderButtons { ...props } />;
                        }
                    })}
                >
                    {( props ) => <WatchScreen /> }
                </Stack.Screen>
            </Stack.Navigator>
        </NavigationContainer>
    );
}
